import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import canada from './img/canada.png';
import america from './img/amrica.png';
import london from './img/london.png';
import spain from './img/spain.png';
import french from './img/french.png';
import sweden from './img/sweden.png';
import italy from './img/italy.png';

const ResponsiveAutoSlider = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(4);

  const handleResize = () => {
    if (window.innerWidth >= 1200) {
      setVisibleItems(6);
    } else if (window.innerWidth >= 992) {
      setVisibleItems(3);
    } else if (window.innerWidth >= 768) {
      setVisibleItems(2);
    } else {
      setVisibleItems(3);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [items.length]);

  const getItemsForSlide = () => {
    let slideItems = [];
    for (let i = 0; i < visibleItems; i++) {
      const index = (currentIndex + i) % items.length;
      slideItems.push(items[index]);
    }
    return slideItems;
  };

  return (
    <div className="slider-wrapper">
      <div className="d-flex justify-content-center overflow-hidden">
        {getItemsForSlide().map((item, subIdx) => (
          <div className="p-2" key={subIdx} style={{ flex: `0 0 ${100 / visibleItems}%` }}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

const App = () => {
  const items = [
    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={canada} alt="" className="img-fluid sliderImg" />
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={america} alt="" className="img-fluid sliderImg" />
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={london} alt="" className="img-fluid sliderImg" />
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={spain} alt="" className="img-fluid sliderImg" />
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={french} alt="" className="img-fluid sliderImg" />
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={sweden} alt="" className="img-fluid sliderImg" />
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={italy} alt="" className="img-fluid sliderImg" />
      </div>
    </div>,
  ];

  return (
    <>
      <section className='bg-light py-4 mt-5 topdes'>
        <div className='container'>
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 data-aos="fade-up">Top Destinations</h2>
              <p data-aos="fade-up">We have quality partners in variety of destinations around the globe.</p>
            </div>
          </div>
          <div className="row py-4 mb-4 destinationCountry">
            <ResponsiveAutoSlider items={items} />
          </div>
          <div className="row position-relative mb-4 align-items-center text-center mx-auto justify-content-center">
            <div className="col-md-4">
                <button data-aos="fade-up" className="btn btn-primary apply-O px-5 py-3">Apply Online</button>
            </div>
        </div>
        </div>
      </section>
    </>
  );
};

export default App;
