import React from 'react'
import post1 from '../img/react-de.jpg'
import post2 from '../img/front-office-receptionist.jpg'
import post3 from '../img/content-writer.jpg'
import banner from '../img/career-updates-main-banner.jpg'


export default function CareerIndex() {
  return (
    <>
        <section className=''>
            <img src={banner} alt="" className="img-fluid w-100"  />        
        </section>


        <section className='services py-5 mb-5 bg-light job'>
            <div className='container'>
                <div className="row">  
                    <div className="col-md-12">
                        <h6 data-aos="fade-up" className='text-center'>Open Positions</h6>
                        <h2 data-aos="fade-up" className='text-center fw-bold pb-4'>Career <span className='text-primary1'>Oppurtiunity</span></h2>
                    </div>
                    <div className="col-md-4 mb-5">
                        <div className="position-relative">
                            <div className="slider-img">
                                <img data-aos="fade-up" src={post1} alt="" className="img-fluid sliderImg" />
                            </div>
                            
                            <div className="slider-text">        
                                <h5 data-aos="fade-up" className='fw-bold'>Web Developer – React JS </h5>
                                
                                <p data-aos="fade-up" className='text-danger'><b>Job Type</b>: Full Time | <a className='text-decoration-none' href=''>Apply Now</a></p>
                            </div>
                        </div>
                    </div> 

                    <div className="col-md-4 mb-5">
                        <div className="position-relative">
                            <div className="slider-img">
                                <img data-aos="fade-up" src={post2} alt="" className="img-fluid sliderImg" />
                            </div>
                            
                            <div className="slider-text">        
                                <h5 data-aos="fade-up" className='fw-bold'>Front Office - Receptionist </h5>
                                
                                <p data-aos="fade-up" className='text-danger'><b>Job Type</b>: Full Time | <a className='text-decoration-none' href=''>Apply Now</a></p>
                            </div>
                        </div>
                    </div> 

                    <div className="col-md-4 mb-5">
                        <div className="position-relative">
                            <div className="slider-img">
                                <img data-aos="fade-up" src={post3} alt="" className="img-fluid sliderImg" />
                            </div>
                            
                            <div className="slider-text">        
                                <h5 data-aos="fade-up" className='fw-bold'>Web Developer – React JS </h5>
                                
                                <p data-aos="fade-up" className='text-danger'><b>Job Type</b>: Full Time | <a className='text-decoration-none' href=''>Apply Now</a></p>
                            </div>
                        </div>
                    </div>   
               
                    
                    
                </div>
                </div>
            
            </section>
    </>
  )
}
