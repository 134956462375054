import './App.css';
import{BrowserRouter, Routes, Route} from 'react-router-dom';
import About from './components/About';
import Navbar from './components/Navbar';
import SliderSec from './components/SliderSec';


import ContactForm from './components/ContactForm';
import Footer from './components/Footer';

import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Contact from './components/Contact';
import ContactTest from './components/ContactTest';
import PartnersAf from './components/PartnersAf';
import MVG from './components/MVG';
import TopDestination from './components/TopDestination';
import TopDestinationNew from './components/TopDestinationNew';
import StudyAbroad from './components/StudyAbroad';
import ServiceSlider from './components/ServiceSlider';
import HowWeWork from './components/HowWeWork';
import FlyDreamDestination from './components/FlyDreamDestination';
import RecentPost from './components/RecentPost';
import SuccessStory from './components/SuccessStory';
import ContactUs from './components/ContactUs';
import Testimonial from './components/Testimonial';
import ConnectWithUs from './components/ConnectWithUs';

import Spouse from './components/SpouseVisa/Spouse';

import DestinationIndex from './components/Destination/DestinationIndex';
import Travel from './components/TravelUpdate/Travel';
import CareerIndex from './components/Career/CareerIndex';
import Appointment from './components/Appointment';
import Stats from './components/Stats';







function App() {
  return (
      
      <BrowserRouter>     
        <Navbar/>
        {/* <div className='mb-100'></div> */}
        <Routes>
          <Route exact path='/' element={
            <>
              <section className='container'>            
                <SliderSec/>                    
              </section>
              <PartnersAf />
              <MVG />
              <TopDestinationNew />
              {/* <TopDestination /> */}
              <Stats></Stats>
              <StudyAbroad />
              <About />
              <ServiceSlider />
              <HowWeWork />
              <FlyDreamDestination />
              <RecentPost />
              <SuccessStory />
              <ContactUs />
              <Testimonial></Testimonial>
              <ConnectWithUs></ConnectWithUs>
              
            </>
          } />
          

          

           

         

             

          <Route path='privacy-policy' element={
            <>
              <section className='bg-about'>
                <PrivacyPolicy/>
              </section>
            </>
          } />

          <Route path='terms-of-use' element={
            <>
              <section className='bg-about'>
                <Terms/>
              </section>
            </>
          } /> 

          <Route path='contact' element={
            <>             
              <Contact/>    
            </>
          } />

          <Route path='spouse-visa' element={
            <>
              
              <Spouse></Spouse>
              

            </>
          } />
          <Route path='destination' element={
            <>
              
              <DestinationIndex></DestinationIndex>           
              

            </>
          } />

          <Route path='travel-updates' element={
            <>             
              <Travel></Travel>
            </>
          } />

          <Route path='career' element={
            <>             
              <CareerIndex></CareerIndex>
            </>
          } />

          
          
             

          <Route path='appointment' element={
            <>
              <Appointment></Appointment>
            </>
          } />     
          
          
          
        </Routes>
        
           
        <Footer></Footer> 
          
       </BrowserRouter> 
  );
}

export default App;
