import React from 'react'
import mvg1 from './img/mvg-1.png'
import mission from './img/mission.png'
import vision from './img/vision.png'
import goal from './img/goal.png'

export default function MVG() {
  return (
    <section className='py-4 bg-light mvg'>
        <div className="container">
            <div className="row">
                <div className="col-md-6 p-5">
                    <img src={mvg1} alt="" className="img-fluid p-2 border-r mvg-1" data-aos="fade-up" />
                </div>
                <div className="col-md-6 p-5">
                    <div className='row mb-5'>
                        <div className='col-2'>
                            <img src={mission} alt="" className="img-fluid" data-aos="fade-up" />
                        </div>
                        <div className="col-10">
                            <h4  data-aos="fade-up" className='primary-text'>MISSION</h4>
                            <p data-aos="fade-up">Our Mission is to open the door of opportunity effectively and competently to those individuals who would like to build a better future by study abroad.</p>
                        </div>
                    </div>

                    <div className='row mb-5'>
                        <div className='col-2'>
                            <img data-aos="fade-up" src={mission} alt="" className="img-fluid" />
                        </div>
                        <div className="col-10">
                            <h4 data-aos="fade-up" className='primary-text'>VISION</h4>
                            <p data-aos="fade-up">Our vision is to become an integral consulting company to which people turn to for education, career and professional development services.</p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-2'>
                            <img data-aos="fade-up" src={mission} alt="" className="img-fluid" />
                        </div>
                        <div className="col-10">
                            <h4 data-aos="fade-up" className='primary-text'>GOAL</h4>
                            <p data-aos="fade-up">we believe in honesty, integrity, and trust. We believe that by promoting professionalism and honesty in all aspects of our legal representation.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
