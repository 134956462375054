import React from 'react'
import mapLocation from './img/map-location.png'

export default function ConnectWithUs() {
  return (
    <section className="connect-with-us py-5">
        <div className="container py-5">
            <div className="row align-items-center">
                <div className="col-md-9">
                    <img src={mapLocation} alt="img" className="img-fluid" />
                </div>
                <div className="col-md-3">
                    <h2 data-aos="fade-up" className='h1 fw-bold xl'>Connect</h2>
                    <h4 data-aos="fade-up" className='text-end'>WITH US</h4>
                    <p data-aos="fade-up" className='py-3'>We look forward to meeting you.
                    Call (910) 12345678 or request an appointment online to set up your first visit. We’ll be in touch soon.</p>
                    <button data-aos="fade-up" className="btn btn-primary rounded-pill px-4 mb-4">&nbsp;SCHEDULE APPOINTMENT&nbsp;</button>
                    <button data-aos="fade-up" className="btn btn-primary rounded-pill px-5">LOCATION & HOURS</button>
                </div>
            </div>
        </div>
    </section>
  )
}
