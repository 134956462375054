import React from 'react'
import punjab from '../img/punjab.jpg'
import CTA from '../CTA'
import uk from '../img/uk-f.png'
import germany from '../img/germany-f.png'
import usa from '../img/usa-f.png'
import prepaidCard from '../img/prepaid-card.jpeg'
import canada from '../img/canada-f.png'
import ireland from '../img/ireland-f.png'
import swit from '../img/switzerland-f.png'
import banner from '../img/travel-updates-main-banner.jpg'
import simcard from '../img/sim-card-1.jpg'
import spouseVisa from '../img/spouse-visa-1.jpg'
import RecentPost from '../RecentPost'

export default function Travel() {
  return (
    <>

    <section className=''>
      <img src={banner} alt="" className="img-fluid w-100"  />        
    </section>
    
    {/* <section className="pt-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className="h1 fw-bold text-primary1">
            Bank Partner Association
            </h2>
            <p>We are proud to say that we are the first agency to start spouse visa in the region and with over <span className="text-primary1 h6">1000+ happy customers</span> along with <span className="text-primary1 h6">100% visa success rate</span>. 
              We will analyse your and your spouse profile and select the best course of action accordingly. 
            </p>
            <button data-aos="fade-up" className='btn btn-primary px-4 mb-5'>Get IN Touch With Us! <i className='bi bi-arrow-right-square'></i></button>

          </div>
          <div className="col-md-6">
            <img src={spouseVisa} alt="" className="img-fluid rounded-3" />
          </div>
          <div className="col-md-12 py-3">
            <h5>Kindly fix and appointment asap as <span className="text-primary1 h4">we take limited numbers of spouse cases every month</span> as we don’t compromise on the quality and timeline decided for the applying for your spouse visa.</h5>
          </div>
        </div>
      </div>
    </section> */}

    <section className="py-5">
      <div className="container">
        <div className="row align-items-center justify-content-center">
            
          <div className="col-md-8 text-center">
            <h2 className="h1 fw-bold text-primary1">
                Insurance Partner
            </h2>
            <p>We are proud to say that we are the first agency to start spouse visa in the region and with over <span className="text-primary1 h6">1000+ happy customers</span> along with <span className="text-primary1 h6">100% visa success rate</span>. 
              We will analyse your and your spouse profile and select the best course of action accordingly. 
            </p>
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
            
          <div className="col-md-10 text-center">
            <div className="row">

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={uk} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>UK</h5>
                    </div>                    
                </div>

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={germany} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>Germany</h5>
                    </div>                    
                </div>

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={usa} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>USA</h5>
                    </div>                    
                </div>

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={canada} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>CANADA</h5>
                    </div>                    
                </div>


                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={ireland} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>IRELAND</h5>
                    </div>                    
                </div>

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={swit} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>SWITZERLAND</h5>
                    </div>                    
                </div>

            
            </div>  
          </div>        
          
        </div>
      </div>
    </section>
    <CTA></CTA>
    <section className="pt-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className="h1 fw-bold text-primary1">
            Sim Card
            </h2>
            <p>We are proud to say that we are the first agency to start spouse visa in the region and with over <span className="text-primary1 h6">1000+ happy customers</span> along with <span className="text-primary1 h6">100% visa success rate</span>. 
              We will analyse your and your spouse profile and select the best course of action accordingly. 
            </p>
            <button data-aos="fade-up" className='btn btn-primary px-4 mb-5'>Get Your Sim Card! <i className='bi bi-arrow-right-square'></i></button>

          </div>
          <div className="col-md-6">
            <img src={simcard} alt="" className="img-fluid rounded-3" />
          </div>
          
        </div>
      </div>
    </section>

    <section className="pt-5">
      <div className="container">
        <div className="row align-items-center mob-cr">
            <div className="col-md-6">
                <img src={prepaidCard} alt="" className="img-fluid rounded-3" />
            </div>
          <div className="col-md-6">
            <h2 className="h1 fw-bold text-primary1">
            Prepaid Card
            </h2>
            <p>We are proud to say that we are the first agency to start spouse visa in the region and with over <span className="text-primary1 h6">1000+ happy customers</span> along with <span className="text-primary1 h6">100% visa success rate</span>. 
              We will analyse your and your spouse profile and select the best course of action accordingly. 
            </p>
            <button data-aos="fade-up" className='btn btn-primary px-4 mb-5'>Get Your Card! <i className='bi bi-arrow-right-square'></i></button>

          </div>
          
          
        </div>
      </div>
    </section>
    <section className="py-5">
      <div className="container">
        <div className="row align-items-center justify-content-center">
            
          <div className="col-md-8 text-center">
            <h2 className="h1 fw-bold text-primary1">
                Bank Loan / Study Loan
            </h2>
            <p>We are proud to say that we are the first agency to start spouse visa in the region and with over <span className="text-primary1 h6">1000+ happy customers</span> along with <span className="text-primary1 h6">100% visa success rate</span>. 
              We will analyse your and your spouse profile and select the best course of action accordingly. 
            </p>
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
            
          <div className="col-md-10 text-center">
            <div className="row">

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={uk} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>UK</h5>
                    </div>                    
                </div>

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={germany} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>Germany</h5>
                    </div>                    
                </div>

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={usa} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>USA</h5>
                    </div>                    
                </div>

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={canada} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>CANADA</h5>
                    </div>                    
                </div>


                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={ireland} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>IRELAND</h5>
                    </div>                    
                </div>

                <div className="col-md-2 col-6">
                    <div className="countryinfo">
                        <img src={swit} alt="" className="img-fluid" />
                        <h5 className=' pt-2 text-center'>SWITZERLAND</h5>
                    </div>                    
                </div>

            
            </div>  
          </div>        
          
        </div>
      </div>
    </section>
    <CTA></CTA>

    <RecentPost></RecentPost>
    </>
    
  )
}
