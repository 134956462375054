import React, { useState } from "react";

import punjab from '../img/punjab.jpg'
import spouseVisa from '../img/spouse-visa.jpeg'
import ContactForm from "../ContactForm";
import CTA from "../CTA";
import Testimonial from "./Testimonial";
import RecentPost from "../RecentPost";
import banner from '../img/Banner_Spouse Visa.png'


export default function Spouse() {
  return (
    <>
    <section className=''>
      <img src={banner} alt="" className="img-fluid" />
        <div className="container d-none">
            <div className="row align-items-center justify-content-center text-center">
                <div className="col-md-7 py-5">
                    <h1 data-aos="fade-up"><span className="text-white fw-bold">Grow quickly, Manage easily- Make your students happy with Kaur Immigration</span></h1>                
                    <p data-aos="fade-up text-white"><span className="text-white">Experience a fast, transparent, and smooth application process along with the best commissions. With Kaur Immigration, you can just focus on your students and leave the rest to us. With our 20+ years of experience and a large network of 900+ Universities, your success is guaranteed!</span> </p>
                    {/* <h6 data-aos="fade-up text-white"><span className="text-white">For more info</span></h6> */}
                    <button data-aos="fade-up" className='btn btn-primary'>Contact Us! <i className='bi bi-arrow-right-square'></i></button>
                </div>
            </div>
        </div>
    </section>

    <section className="pt-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className="h1 fw-bold text-primary1">
              Spouse Visa
            </h2>
            <p>We are proud to say that we are the first agency to start spouse visa in the region and with over <span className="text-primary1 h6">1000+ happy customers</span> along with <span className="text-primary1 h6">100% visa success rate</span>. 
              We will analyse your and your spouse profile and select the best course of action accordingly. 
            </p>
            <button data-aos="fade-up" className='btn btn-primary px-4 mb-5'>Get Your Visa! <i className='bi bi-arrow-right-square'></i></button>

          </div>
          <div className="col-md-6">
            <img src={spouseVisa} alt="" className="img-fluid rounded-3" />
          </div>
          <div className="col-md-12 py-3">
            <h5>Kindly fix and appointment asap as <span className="text-primary1 h4">we take limited numbers of spouse cases every month</span> as we don’t compromise on the quality and timeline decided for the applying for your spouse visa.</h5>
          </div>
        </div>
      </div>
    </section>

    <section className="">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h4 className="fw-bold text-center">
            
            </h4>
          </div>
        </div>
      </div>
    </section>
    <ContactForm></ContactForm>
    <CTA></CTA>
    <section className="pt-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className="h1 fw-bold text-primary1">
              Spouse Visa
            </h2>
            <p>We are trailblazers in the realm of Canadian spouse visas and extend our expertise to provide exceptional counselling for other destinations as well. Our focus is on understanding your aspirations for a lifestyle within a family setting and guiding you in selecting the most suitable destinations for the next two decades.
            </p>
            <p>We encourage you to connect with us to explore the optimal timing, potential options, and the best strategies for your journey ahead. Making informed decisions at the right moment can significantly impact your future, so let’s collaborate to chart the best course.</p>
            <h6>Reach out to us for a detailed conversation over a phone or video call. Let's plan your next move with confidence and clarity.</h6>
            <button data-aos="fade-up" className='btn btn-primary px-4 mb-5'>Get Your Visa! <i className='bi bi-arrow-right-square'></i></button>

          </div>
          <div className="col-md-6">
            <img src={punjab} alt="" className="img-fluid" />
          </div>
          
        </div>
      </div>
    </section>
    <Testimonial></Testimonial>
    <RecentPost></RecentPost>
    

    
    </>
  )
}
